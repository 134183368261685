import axios from "axios";

const apiKey = process.env.REACT_APP_APIKEY;
const websiteCode = "AHR";

const getCookie = (name) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    // Check if this cookie is the one we are looking for
    if (cookie.startsWith(name + "=")) {
      // Return the value of the cookie
      return cookie.substring(name.length + 1);
    }
  }
  // Return null if the cookie is not found
  return null;
};

// Usage example
const token = getCookie("token1");
//   const websiteId = getCookie('websiteId') ? getCookie('websiteId') : 0;

//const token = getCookie('token1');
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
  withCredentials: true,
};

export const getResortData = async () => {
  try {
    const { data } = await axios.get(`${apiKey}/other/get-ahr-resorts-list`);
    return data;
  } catch (error) {
    throw Error(error.response.message);
  }
};

export const getOfferData = async () => {
  try {
    const { data } = await axios.get(
      `${apiKey}/websites/${websiteCode}/offers`,
      {},
      config
    );
    return data;
  } catch (error) {
    throw Error(error.response.message);
  }
};

export const getFeaturedOfferData = async () => {
  try {
    const { data } = await axios.get(
      `${apiKey}/websites/${websiteCode}/featured-offers`,
      {},
      config
    );
    return data;
  } catch (error) {
    throw Error(error.response.message);
  }
};

export const getSingleOfferData = async (offerId) => {
  try {
    const { data } = await axios.get(
      `${apiKey}/websites/${websiteCode}/offers/${offerId}`,
      {},
      config
    );
    return data;
  } catch (error) {
    throw Error(error.response.message);
  }
};
export const getAllPressRelease = async () => {
  try {
    const { data } = await axios.get(
      `${apiKey}/websites/${websiteCode}/press-releases`,
      {},
      config
    );
    return data;
  } catch (error) {
    throw Error(error.response.message);
  }
};
// websites/websiteCode/press-releases/4

export const getSinglePressReleaseData = async (websitePressReleaseId) => {

  try {
    const { data } = await axios.get(
      `${apiKey}/websites/${websiteCode}/press-releases/${websitePressReleaseId}`,
      {},
      config
    );

    return data;
  } catch (error) {
    throw Error(error.response.message);
  }

};

export const insertEnquiry = async (formdata) => {
  try {
   
    const { data } = await axios.post(
      `${apiKey}/contact/${websiteCode}/insert-brand-enquiry/`,
      formdata,
      {
        withCredentials: "true",
      }

    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getAllResorts = async () => {
  try {
    // const formData = new FormData();
    // formData.append("resortcode", "");
    // //   const { data } = await axios.get(
    // //     `${apiKey}/contact/get-resorts-list`
    // //   );
    const { data } = await axios.get(
      `${apiKey}/contact/get-resorts-list/`
      // formData,
      // {
      //   withCredentials: "true",
      // }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllEnquiryTypes = async () => {
  try {
    const { data } = await axios.get(`${apiKey}/contact/get-enquiry-type-list`);

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getAspectRatio = async (offer_aspect_ratio) => {
  try {
    const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
    const { data } = await axios.get(
      `${apiKey}/common/aspect-ratio/${offer_aspect_ratio}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getCanonicalUrl = async (canonical_url_ahr) => {

  try {
    const websiteIds = getCookie("websiteId") ? getCookie("websiteId") : 0;
    const { data } = await axios.get(
      `${apiKey}/common/aspect-ratio/${canonical_url_ahr}`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getWebMenu = async () => {
  try {
    const { data } = await axios.get(
      `${apiKey}/websites/${websiteCode}/website-menus`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

// export const getPageData = async (pageCode) => {
//   if (pageCode !== "") {
//     try {
//       const { data } = await axios.get(
//         `${apiKey}/websites/${websiteCode}/pages-data/${pageCode}`,
//         config
//       );
//       return data;
//     } catch (err) {
//       throw Error(err.response.data.message);
//     }
//   }
// };
export const getPageData = async (pageCode, isPreview, userId, sectionId) => {
  if (pageCode !== "") {
    try {

      const encryptedUserId = userId;
      if (isPreview) {
        const requestData = {
          ispreview: isPreview,
          uid: encryptedUserId,
          secId: sectionId,
        };
        const config1 = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
          //data: requestData
        };
        const { data } = await axios.get(
          `${apiKey}/websites/${websiteCode}/pages-data/${pageCode}?ispreview=${isPreview}&&uid=${encryptedUserId}&&secid=${sectionId}`,
          config1
        );
        return data;
      }
      else {
        const { data } = await axios.get(
          `${apiKey}/websites/${websiteCode}/pages-data/${pageCode}`,
          config
        );
        return data;
      }
    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};

export const getPageData1 = async (pageCode, isPreview, userId, sectionId) => {
  if (pageCode !== "") {
    try {
      const encryptedUserId = userId;
      if (isPreview) {
        const requestData = {
          ispreview: isPreview,
          uid: encryptedUserId,
          secId: sectionId,
        };
        const config1 = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
          //data: requestData
        };
        const { data } = await axios.get(
          `${apiKey}/websites/${websiteCode}/pages-data1/${pageCode}?ispreview=${isPreview}&&uid=${encryptedUserId}&&secid=${sectionId}`,
          config1
        );
        return data;

      }
      else {
        const { data } = await axios.get(
          `${apiKey}/websites/${websiteCode}/pages-data1/${pageCode}`,
          config
        );
        return data;

      }

    } catch (err) {
      throw Error(err.response.data.message);
    }
  }
};

export const newsLetter = async (email) => {
  try {
    const response = await axios.post(
      `${apiKey}/newsletter/${websiteCode}/add`, {
      email: email
    }, {
      withCredentials: "true",
    }
    );

    return response;
  } catch (err) {
    throw Error(err.response.data.message);
  }
}
export const getPagesSlug = async () => {
  try {
    const { data } = await axios.get(
      `${apiKey}/websites/${websiteCode}/routes`,
      config
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getAllAwards = async () => {
  try {
    const { data } = await axios.get(
      `${apiKey}/websites/${websiteCode}/awards`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getAllCollateral = async () => {
  try {
    const { data } = await axios.get(
      `${apiKey}/websites/${websiteCode}/collateral`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getResortName = async () => {
  try {
    const { data } = await axios.get(
      `${apiKey}/common/resort-list`,
      config
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};